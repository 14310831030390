import {getCoreAuthDomain} from '../../../loginButton/authorize/authorizeV2';
import {PAY_AUTH_DOMAIN} from '../../../../common/utils/urls';

const RESULT_PATH = '/pay/installments/prequalifications/authorize';
const OVERLAY_PATH = '/shopify_pay/prequal_authorize';

export const buildOverlayUrl = () => {
  const coreDomain = getCoreAuthDomain();
  return `${coreDomain}${OVERLAY_PATH}?target_origin=${window.location.origin}`;
};

export const buildPrequalResultUrl = (analyticsTraceId: string) => {
  const payDomain = PAY_AUTH_DOMAIN.replace('https://', '');
  return `${PAY_AUTH_DOMAIN}${RESULT_PATH}?shopify_domain=${window.location.hostname}&pay_domain=${payDomain}&analytics_trace_id=${analyticsTraceId}&redirect_source=${window.location.origin}`;
};
