import WebComponent from '../../../../common/WebComponent';
import {ShopPayLogo} from '../../../../common/shop-pay-logo';
import {openPopupWindow} from '../../../../common/utils';
import {defineCustomElement} from '../../../../common/init';
import MessageListener from '../../../../common/MessageListener';
import {PrequalMessageEventData as MessageEventData} from '../../../../types';
import {WindowEventSource} from '../../../../common/MessageEventSource';
import {PAY_AUTH_DOMAIN} from '../../../../common/utils/urls';

import {OVERLAY_HTML} from './constants';
import {buildOverlayUrl} from './utilities';

const OVERLAY_ACTION_CLOSE = 'close';
const OVERLAY_ACTION_SUCCESS = 'prequal_buyer_upsert_successful';
const OVERLAY_ACTION_ERROR = 'error';

export class InstallmentsPrequalOverlayModal extends WebComponent {
  #window?: Window | null;
  _popupListener?: MessageListener<MessageEventData>;

  constructor() {
    super();

    if (!customElements.get('shop-pay-logo')) {
      customElements.define('shop-pay-logo', ShopPayLogo);
    }
  }

  connectedCallback() {
    const template = document.createElement('template');
    template.innerHTML = OVERLAY_HTML;
    this.attachShadow({mode: 'open'}).append(template.content.cloneNode(true));
    this.#buildOnClick();
  }

  disconnectedCallback(): void {
    this._popupListener?.destroy();
    this._popupListener = undefined;
  }

  attributeChangedCallback() {}

  handlePostMessage(event: MessageEventData) {
    // User has chosen to close the window
    if (event.type === OVERLAY_ACTION_CLOSE) {
      this.#window?.close();
      this.dispatchCustomEvent('overlayClose');
    }
    // User has successfully completed the Buyer Onboarding Form
    if (event.type === OVERLAY_ACTION_SUCCESS) {
      this.#window?.close();
      this.dispatchCustomEvent('buyerOnboardingSuccess');
    }
    // User cannot onboard onto installments
    // Close overlay and modal
    if (event.type === OVERLAY_ACTION_ERROR) {
      this.#window?.close();
      this.dispatchCustomEvent('closeOverlayAndModal');
    }
  }

  handleContinueButtonClick() {
    if (this.#window && !this.#window.closed) {
      this.#window.focus();
      this.addPostMessageEventListener();
    } else {
      const overlayUrl = buildOverlayUrl();
      this.#window = openPopupWindow({
        url: overlayUrl,
        width: 500,
        height: 750,
      });

      if (this.#window) {
        this.#window.focus();
        this.addPostMessageEventListener();
      }
    }
  }

  addPostMessageEventListener() {
    if (this.#window) {
      this._popupListener = new MessageListener<MessageEventData>(
        new WindowEventSource(this.#window),
        [PAY_AUTH_DOMAIN],
        this.handlePostMessage.bind(this),
      );
    }
  }

  #buildOnClick() {
    const continueLink = this.shadowRoot?.querySelector(
      '#installments-prequal-overlay-continue',
    );

    if (continueLink) {
      (continueLink as HTMLAnchorElement).onclick = () => {
        this.handleContinueButtonClick();
      };
    }
  }
}

/**
 * Define the installments-prequal-overlay-modal custom element.
 */
export function defineElement() {
  defineCustomElement(
    'installments-prequal-overlay-modal',
    InstallmentsPrequalOverlayModal,
  );
}
