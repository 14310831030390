import {startBugsnag, isBrowserSupported} from '../../common/init';
import {defineElement as defineShopPayButton} from '../payButton/shop-pay-button';

import {defineElement as definePaymentTerms} from './shopify-payment-terms';
import {defineElement as defineShopPayBanner} from './components/shop-pay-banner';
import {defineElement as defineShopPayInstallmentsBanner} from './components/shop-pay-installments-banner';
import {defineElement as defineShopPayInstallmentsPrequalOverlayModal} from './components/prequalOverlay/installments-prequal-overlay-modal';

import {definePrequalAmountElement} from '.';

/**
 * Initialize the payment terms web components.
 * This is the entry point that will be used for code-splitting.
 */
function init() {
  if (!isBrowserSupported()) return;
  startBugsnag({
    bundle: 'paymentTerms',
    // eslint-disable-next-line no-process-env
    bundleLocale: process.env.BUILD_LOCALE,
  });

  defineShopPayButton();
  definePaymentTerms();
  defineShopPayBanner();
  definePrequalAmountElement();
  defineShopPayInstallmentsBanner();
  defineShopPayInstallmentsPrequalOverlayModal();
}

init();
